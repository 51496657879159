import React from 'react';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import Banner from '../components/Banner';

import flags from '../assets/images/bannerImages/flags-banner.jpg';
import glowingAds from '../assets/images/bannerImages/signboards-banner.jpg';
import printing from '../assets/images/bannerImages/printing-banner.jpg';
import transport from '../assets/images/bannerImages/transport.jpg';
import clothes from '../assets/images/bannerImages/maikes1.jpg';
import standsBanner from '../assets/images/bannerImages/IMG-1906.jpg';
import brandingStyle from '../assets/images/bannerImages/branding-style-banner.jpg';
import widePrint from '../assets/images/bannerImages/wide-print-banner.jpg';

export default () => (
  <Layout>
    <Helmet
      title="Velgama - reklamos paslaugos"
      meta={[
        { name: 'description', content: 'Reklamos paslaugų reprezentacinis puslapis' },
        { name: 'keywords', content: '' }, // TODO
      ]}
    />

    <Banner h1="Velgama" paragraph="Reklamos paslaugos Jums. Sukursime ir pagaminsime." bannerType="main-banner" />

    <div id="main">
      <section id="one" className="tiles">
        <article style={{ backgroundImage: `url(${flags})` }}>
          <header className="major">
            <h3>Vėliavos. Baneriai</h3>
            <p>Valstybinės arba su Jūsų logotipu</p>
          </header>
          <Link to="/veliavos" className="link primary" />
        </article>
        <article style={{ backgroundImage: `url(${glowingAds})` }}>
          <header className="major">
            <h3>Iškabos</h3>
            <p>Reklaminio projekto paruošimas ir derinimas su miesto savivaldybe</p>
          </header>
          <Link to="/iskabos" className="link primary" />
        </article>
        <article style={{ backgroundImage: `url(${standsBanner})` }}>
          <header className="major">
            <h3>Stendai</h3>
            <p>Kompleksinė reklama</p>
          </header>
          <Link to="/stendai" className="link primary" />
        </article>
        <article style={{ backgroundImage: `url(${printing})` }}>
          <header className="major">
            <h3>Spaudos darbai</h3>
            <p>
Kalendoriai, papkės, lankstinukai, skrajutės, vizitinės kortelės, iškilūs
               bei šilkografiniai lipukai ir kita

            </p>
          </header>
          <Link to="/spauda" className="link primary" />
        </article>
        <article style={{ backgroundImage: `url(${transport})` }}>
          <header className="major">
            <h3>Transportas</h3>
            <p>Nuo projekto iki apklijavimo</p>
          </header>
          <Link to="/transportas" className="link primary" />
        </article>
        <article style={{ backgroundImage: `url(${clothes})` }}>
          <header className="major">
            <h3>Drabužiai</h3>
            <p>Darbo drabužiai, marškinėliai, kepurės</p>
          </header>
          <Link to="/drabuziai" className="link primary" />
        </article>
        <article style={{ backgroundImage: `url(${brandingStyle})` }}>
          <header className="major">
            <h3>Firminis stilius</h3>
            <p>LOGOTIPAI, BLANKAI, VIZITINĖS KORTELĖS...</p>
          </header>
          <Link to="/firminis-stilius" className="link primary" />
        </article>
        <article style={{ backgroundImage: `url(${widePrint})` }}>
          <header className="major">
            <h3>Plačiaformatė spauda</h3>
            <p>Tentai, paprasta ir perforuota plėvelė. Stiklų matinimas šerkšno plėvele</p>
          </header>
          <Link to="/placiaformate-spauda" className="link primary" />
        </article>
      </section>
    </div>

  </Layout>
);
